import { Network, getNetworkEndpoints } from "@injectivelabs/networks"
import { ChainGrpcBankApi, ChainGrpcTokenFactoryApi, ChainGrpcExchangeApi, ChainGrpcGovApi, IndexerGrpcAccountApi, IndexerGrpcAccountPortfolioApi, IndexerGrpcSpotApi, ChainGrpcWasmApi } from "@injectivelabs/sdk-ts"
import { ChainId } from "@injectivelabs/ts-types";
import { WalletStrategy, Wallet, MsgBroadcaster } from "@injectivelabs/wallet-ts"


export const cache = ref({} as Record<string, Service>);

interface Service {
  walletStrategy: WalletStrategy;
  msgBroadcastClient: MsgBroadcaster;
  bankApi: ChainGrpcBankApi,
  wasmApi: ChainGrpcWasmApi,
  exchangeApi: ChainGrpcExchangeApi,
  govApi: ChainGrpcGovApi,
  tokenFactoryApi: ChainGrpcTokenFactoryApi,
  indexerAccountApi: IndexerGrpcAccountApi,
  indexerAccountPortfolioApi: IndexerGrpcAccountPortfolioApi,
  indexerGrpcSpotApi: IndexerGrpcSpotApi;

}
export function useChainService() {
  const { chainId, network, ethereumChainId } = useAppStore()

  if (cache.value[chainId]) {
    return cache.value[chainId]
  }

  const endpoints = getNetworkEndpoints(network)

  const walletStrategy = new WalletStrategy({
    chainId,
    ethereumOptions: {
      ethereumChainId,
      rpcUrl:
        chainId === ChainId.Mainnet ? 'https://eth-mainnet.g.alchemy.com/v2/xvBpvW4QoQbNaWH2FfnAJqOGj7eVTla3' : 'https://eth-sepolia.g.alchemy.com/v2/QMAB-ff5mVvVNiThapgsdUgmFUehwnSk'
    },
    disabledWallets: [Wallet.CosmostationEth]
  })

  walletStrategy.setOptions({
    metadata: {
      projectId: '83a7846aedc5252494e397134693ea73',
    }
  })


  const bankApi = new ChainGrpcBankApi(endpoints.grpc)

  const tokenFactoryApi = new ChainGrpcTokenFactoryApi(endpoints.grpc)
  const exchangeApi = new ChainGrpcExchangeApi(endpoints.grpc)
  const govApi = new ChainGrpcGovApi(endpoints.grpc)
  const wasmApi = new ChainGrpcWasmApi(endpoints.grpc)

  const indexerAccountApi = new IndexerGrpcAccountApi(endpoints.indexer)
  const indexerAccountPortfolioApi = new IndexerGrpcAccountPortfolioApi(
    endpoints.indexer
  )

  const indexerGrpcSpotApi = new IndexerGrpcSpotApi(endpoints.indexer)

  // Transaction broadcaster
  const msgBroadcastClient = new MsgBroadcaster({
    walletStrategy,
    network: network,
    endpoints: endpoints,
    simulateTx: true
  })



  cache.value[chainId] = {
    walletStrategy,
    msgBroadcastClient,
    bankApi,
    wasmApi,
    exchangeApi,
    govApi,
    tokenFactoryApi,
    indexerAccountApi,
    indexerAccountPortfolioApi,
    indexerGrpcSpotApi
  }

  return cache.value[chainId]
}

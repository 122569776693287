import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import buffer_bieIDf9x4O from "/vercel/path0/nuxt-config/buffer.ts";
import handlers_oZWLY9pUCB from "/vercel/path0/plugins/handlers.ts";
import hotjar_pBWYs5JQbk from "/vercel/path0/plugins/hotjar.ts";
import store_9xNuDHGAVU from "/vercel/path0/plugins/store.ts";
import validation_30a3L3OexF from "/vercel/path0/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  buffer_bieIDf9x4O,
  handlers_oZWLY9pUCB,
  hotjar_pBWYs5JQbk,
  store_9xNuDHGAVU,
  validation_30a3L3OexF
]
import { default as _91id_93CiIdyV2iGEMeta } from "/vercel/path0/pages/airdrop/[id].vue?macro=true";
import { default as indexKrMjD3la9vMeta } from "/vercel/path0/pages/airdrop/index.vue?macro=true";
import { default as distributionAD7RtkVh4qMeta } from "/vercel/path0/pages/distribution.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as launchwHyIdxXK7DMeta } from "/vercel/path0/pages/launch.vue?macro=true";
import { default as liquiditySmJqm7gwcmMeta } from "/vercel/path0/pages/liquidity.vue?macro=true";
import { default as market4TKkr7FJuiMeta } from "/vercel/path0/pages/market.vue?macro=true";
import { default as snapshotsB5TJSutZbmMeta } from "/vercel/path0/pages/snapshots.vue?macro=true";
import { default as _91_46_46_46id_93fEhJRPUDShMeta } from "/vercel/path0/pages/tokens/[...id].vue?macro=true";
import { default as _91address_93Jkbgt3d2PEMeta } from "/vercel/path0/pages/tokens/cw20/[address].vue?macro=true";
import { default as indexLxkZYo756JMeta } from "/vercel/path0/pages/tokens/index.vue?macro=true";
export default [
  {
    name: _91id_93CiIdyV2iGEMeta?.name ?? "airdrop-id",
    path: _91id_93CiIdyV2iGEMeta?.path ?? "/airdrop/:id()",
    meta: _91id_93CiIdyV2iGEMeta || {},
    alias: _91id_93CiIdyV2iGEMeta?.alias || [],
    redirect: _91id_93CiIdyV2iGEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/airdrop/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKrMjD3la9vMeta?.name ?? "airdrop",
    path: indexKrMjD3la9vMeta?.path ?? "/airdrop",
    url: "/airdrop",
    meta: indexKrMjD3la9vMeta || {},
    alias: indexKrMjD3la9vMeta?.alias || [],
    redirect: indexKrMjD3la9vMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/airdrop/index.vue").then(m => m.default || m)
  },
  {
    name: distributionAD7RtkVh4qMeta?.name ?? "distribution",
    path: distributionAD7RtkVh4qMeta?.path ?? "/distribution",
    url: "/distribution",
    meta: distributionAD7RtkVh4qMeta || {},
    alias: distributionAD7RtkVh4qMeta?.alias || [],
    redirect: distributionAD7RtkVh4qMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/distribution.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    url: "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: launchwHyIdxXK7DMeta?.name ?? "launch",
    path: launchwHyIdxXK7DMeta?.path ?? "/launch",
    url: "/launch",
    meta: launchwHyIdxXK7DMeta || {},
    alias: launchwHyIdxXK7DMeta?.alias || [],
    redirect: launchwHyIdxXK7DMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/launch.vue").then(m => m.default || m)
  },
  {
    name: liquiditySmJqm7gwcmMeta?.name ?? "liquidity",
    path: liquiditySmJqm7gwcmMeta?.path ?? "/liquidity",
    url: "/liquidity",
    meta: liquiditySmJqm7gwcmMeta || {},
    alias: liquiditySmJqm7gwcmMeta?.alias || [],
    redirect: liquiditySmJqm7gwcmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/liquidity.vue").then(m => m.default || m)
  },
  {
    name: market4TKkr7FJuiMeta?.name ?? "market",
    path: market4TKkr7FJuiMeta?.path ?? "/market",
    url: "/market",
    meta: market4TKkr7FJuiMeta || {},
    alias: market4TKkr7FJuiMeta?.alias || [],
    redirect: market4TKkr7FJuiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/market.vue").then(m => m.default || m)
  },
  {
    name: snapshotsB5TJSutZbmMeta?.name ?? "snapshots",
    path: snapshotsB5TJSutZbmMeta?.path ?? "/snapshots",
    url: "/snapshots",
    meta: snapshotsB5TJSutZbmMeta || {},
    alias: snapshotsB5TJSutZbmMeta?.alias || [],
    redirect: snapshotsB5TJSutZbmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/snapshots.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46id_93fEhJRPUDShMeta?.name ?? "tokens-id",
    path: _91_46_46_46id_93fEhJRPUDShMeta?.path ?? "/tokens/:id(.*)*",
    meta: _91_46_46_46id_93fEhJRPUDShMeta || {},
    alias: _91_46_46_46id_93fEhJRPUDShMeta?.alias || [],
    redirect: _91_46_46_46id_93fEhJRPUDShMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tokens/[...id].vue").then(m => m.default || m)
  },
  {
    name: _91address_93Jkbgt3d2PEMeta?.name ?? "tokens-cw20-address",
    path: _91address_93Jkbgt3d2PEMeta?.path ?? "/tokens/cw20/:address()",
    meta: _91address_93Jkbgt3d2PEMeta || {},
    alias: _91address_93Jkbgt3d2PEMeta?.alias || [],
    redirect: _91address_93Jkbgt3d2PEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tokens/cw20/[address].vue").then(m => m.default || m)
  },
  {
    name: indexLxkZYo756JMeta?.name ?? "tokens",
    path: indexLxkZYo756JMeta?.path ?? "/tokens",
    url: "/tokens",
    meta: indexLxkZYo756JMeta || {},
    alias: indexLxkZYo756JMeta?.alias || [],
    redirect: indexLxkZYo756JMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tokens/index.vue").then(m => m.default || m)
  }
]
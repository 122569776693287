import {
  MsgBurn,
  MsgChangeAdmin,
  MsgCreateDenom,
  MsgMint,
  MsgSend,
  MsgMultiSend,
  MsgSetDenomMetadata,
  Coin,
  MsgExecuteContractCompat
} from '@injectivelabs/sdk-ts'
import { ChainId } from '@injectivelabs/ts-types'
import { BigNumber } from '@injectivelabs/utils'
import { useChainService } from '~/composables/useChainService'

export const createNewToken = async (
  symbol: string,
  metadata: {
    symbol: string
    name: string
    description: string
    logo: string
    decimals: number
  }
) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const denom = `factory/${walletStore.injectiveAddress}/${symbol}`
  const msgCreateDenom = MsgCreateDenom.fromJSON({
    sender: walletStore.injectiveAddress,
    subdenom: symbol,
    symbol: symbol,
    name: metadata.name,
    decimals: metadata.decimals,

  })

  const msgMetadata = MsgSetDenomMetadata.fromJSON({
    sender: walletStore.injectiveAddress,
    metadata: {
      base: denom,
      symbol: metadata.symbol,
      name: metadata.name,
      decimals: metadata.decimals,
      denomUnits: [
        {
          denom,
          exponent: 0,
          aliases:
            metadata.decimals > 0
              ? ['u' + metadata.symbol.toLocaleLowerCase()]
              : [metadata.symbol.toLocaleLowerCase()]
        },
        ...[
          ...(metadata.decimals > 0
            ? [
              {
                denom: metadata.symbol,
                exponent: Number(metadata.decimals),
                aliases: []
              }
            ]
            : ([] as any))
        ]
      ],
      description: metadata.description,
      display: metadata.decimals > 0 ? metadata.symbol : denom,
      uri: metadata.logo,
      uriHash: ''
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msgCreateDenom, msgMetadata]
  })
}

export const updateTokenMetadata = async (
  denom: string,
  metadata: {
    symbol: string
    name: string
    description: string
    logo: string
    decimals: number
  }
) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const msg = MsgSetDenomMetadata.fromJSON({
    sender: walletStore.injectiveAddress,
    metadata: {
      base: denom,
      symbol: metadata.symbol,
      name: metadata.name,
      decimals: metadata.decimals,
      denomUnits: [
        {
          denom,
          exponent: 0,
          aliases:
            metadata.decimals > 0
              ? ['u' + metadata.symbol.toLocaleLowerCase()]
              : [metadata.symbol.toLocaleLowerCase()]
        },
        ...[
          ...(metadata.decimals > 0
            ? [
              {
                denom: metadata.symbol,
                exponent: Number(metadata.decimals),
                aliases: []
              }
            ]
            : ([] as any))
        ]
      ],
      description: metadata.description,
      display: metadata.decimals > 0 ? metadata.symbol : denom,
      uri: metadata.logo,
      uriHash: ''
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const mintToken = async (denom: string, amount: string) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const msg = MsgMint.fromJSON({
    sender: walletStore.injectiveAddress,

    amount: {
      denom,
      amount
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const burnToken = async (denom: string, amount: string) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const msg = MsgBurn.fromJSON({
    sender: walletStore.injectiveAddress,

    amount: {
      denom,
      amount
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const transferToken = async (
  to: string,
  denom: string,
  amount: string
) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const msg = MsgSend.fromJSON({
    srcInjectiveAddress: walletStore.injectiveAddress,
    dstInjectiveAddress: to,
    amount: {
      denom,
      amount
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const transferMultiToken = async (
  to: { address: string; amount: string; denom: string }[]
) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const result = [] as any[]
  to.reduce((res: any, value: any) => {
    if (!res[value.denom]) {
      res[value.denom] = { amount: new BigNumber(0), denom: value.denom }
      result.push(res[value.denom])
    }
    res[value.denom].amount = new BigNumber(value.amount.trim()).plus(
      res[value.denom].amount
    )
    return res
  }, {})
  const msg = MsgMultiSend.fromJSON({
    inputs: [
      {
        address: walletStore.injectiveAddress,
        coins: result.map((x) => ({ ...x, amount: x.amount.toFixed() }))
      }
    ],
    outputs: to.map((x) => ({
      address: x.address,
      coins: [{ amount: x.amount.toString(), denom: x.denom }]
    }))
  })

  return await msgBroadcastClient.broadcastV2({
    gas: {
      // gas: 22500 * to.length
      gas: 50_000_000
    },
    memo: 'Powered by TokenStation.app',
    address: walletStore.injectiveAddress,
    msgs: [msg]
  })
}

export const changeTokenAdmin = async (denom: string, newAdmin: string) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const msg = MsgChangeAdmin.fromJSON({
    sender: walletStore.injectiveAddress,
    denom,
    newAdmin
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const cw20Convert = async (cw20Denom: string, amount: string) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const { cw20Adapter } = useAppStore()

  const msg = MsgExecuteContractCompat.fromJSON({
    sender: walletStore.injectiveAddress,
    contractAddress: cw20Denom,
    msg: {
      send: {
        contract: cw20Adapter,
        amount: amount.toString(),
        msg: ''
      }
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const cw20Redeem = async (cw20Denom: string, amount: string) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const { cw20Adapter } = useAppStore()

  const msg = MsgExecuteContractCompat.fromJSON({
    funds: [{ denom: `factory/${cw20Adapter}/${cw20Denom}`, amount }],
    sender: walletStore.injectiveAddress,
    contractAddress: cw20Adapter,
    msg: {
      redeem_and_transfer: {
        recipient: walletStore.injectiveAddress
      }
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const fetchCw20Balance = async (cw20Denom: string, addr: string) => {
  const { wasmApi, bankApi } = useChainService()

  const balanceResponse = await wasmApi.fetchSmartContractState(cw20Denom, {
    balance: { address: addr }
  })
  const cw20Balance = JSON.parse(
    Buffer.from(balanceResponse.data).toString('utf-8')
  ).balance

  const { cw20Adapter } = useAppStore()
  const bankBalance = await bankApi.fetchBalance({
    accountAddress: addr,
    denom: `factory/${cw20Adapter}/${cw20Denom}`
  })

  return {
    cw20Balance,
    bankBalance: bankBalance.amount
  }
}

export const isCw20Contract = async (cw20Denom: string) => {
  const { wasmApi } = useChainService()

  try {
    const tokenInfoResponse = await wasmApi.fetchSmartContractState(cw20Denom, {
      token_info: {}
    })

    const tokenInfo = JSON.parse(
      Buffer.from(tokenInfoResponse.data).toString('utf-8')
    )

    return !!tokenInfo;
  } catch {
    return false;
  }

}
export const fetchCw20TokenInfo = async (cw20Denom: string) => {
  const { cw20Adapter } = useAppStore()
  const { wasmApi, bankApi, tokenFactoryApi } = useChainService()

  const tokenInfoResponse = await wasmApi.fetchSmartContractState(cw20Denom, {
    token_info: {}
  })
  const tokenInfo = JSON.parse(
    Buffer.from(tokenInfoResponse.data).toString('utf-8')
  )

  const marketingInfoResponse = await wasmApi.fetchSmartContractState(
    cw20Denom,
    { marketing_info: {} }
  )
  const marketingInfo = JSON.parse(
    Buffer.from(marketingInfoResponse.data).toString('utf-8')
  )

  const denomMetadata = await tokenFactoryApi.fetchDenomAuthorityMetadata(
    cw20Adapter,
    cw20Denom
  )

  const isCw20Register = denomMetadata.admin == cw20Adapter;
  let response = {
    ...tokenInfo,
    ...marketingInfo,
    cw20Adapter: isCw20Register
  }

  if (isCw20Register) {
    const bankMetadata = await bankApi.fetchDenomMetadata(`factory/${cw20Adapter}/${cw20Denom}`)
    response = {
      ...response,
      bankMetadata,

      isMetadataNeedUpdate: bankMetadata.name != tokenInfo.name || bankMetadata.symbol != tokenInfo.symbol
    }
  }

  return response
}

export const registerCw20 = async (denom: string, funds: Coin[]) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const { cw20Adapter } = useAppStore()

  const msgCreateDenom = MsgExecuteContractCompat.fromJSON({
    funds,
    sender: walletStore.injectiveAddress,
    contractAddress: cw20Adapter,
    msg: {
      register_cw20_contract: {
        addr: denom
      }
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msgCreateDenom]
  })
}

export const updateCw20AdapterMetadata = async (cw20Denom: string) => {
  const walletStore = useWalletStore()
  const { msgBroadcastClient } = useChainService()

  await walletStore.validate()

  const { cw20Adapter } = useAppStore()

  const msg = MsgExecuteContractCompat.fromJSON({
    sender: walletStore.injectiveAddress,
    contractAddress: cw20Adapter,
    msg: {
      update_metadata: {
        addr: cw20Denom
      }
    }
  })

  await msgBroadcastClient.broadcastV2({
    address: walletStore.injectiveAddress,
    memo: 'Powered by TokenStation.app',
    msgs: [msg]
  })
}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"og:url","property":"og:url","content":"undefined"},{"hid":"description","name":"description","content":"Launch a Token on Injective"},{"hid":"og:description","property":"og:description","content":"Launch a Token on Injective"},{"hid":"twitter:card","property":"twitter:card","content":"summary_large_image"},{"hid":"twitter:description","property":"twitter:description","content":"Launch a Token on Injective"},{"hid":"twitter:title","property":"twitter:title","content":"Token Station"},{"hid":"og:title","property":"og:title","content":"Token Station"},{"hid":"og:site_name","property":"og:site_name","content":"Token Station"},{"hid":"title","property":"title","content":"Token Station"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"shortcut icon","type":"image/png","href":"/favicon.png"},{"rel":"apple-touch-icon","type":"image/png","href":"/favicon.png"}],"style":[],"script":[{"innerHTML":"var exports = {}"}],"noscript":[],"title":"Token Station","htmlAttrs":{"lang":"en"},"bodyAttrs":{"class":"font-sans"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'